const joinedQueryParams = [
    {
        "name": "promotions",
        "in": "query",
        "description": "List of promotions that are to be included in the generated content",
        "schema": {
            "type": "array",
            "items": {
                "type": "string",
                "enum": [
                    "promotion_target"
                ]
            }
        }
    },
    {
        "name": "twitter_enabled",
        "in": "query",
        "description": "Indicates whether related Tweets will be embedded in the content",
        "schema": {
            "type": "boolean",
            "default": false
        }
    },
    // {
    //     "name": "enable_api",
    //     "in": "query",
    //     "description": "Internal parameter, indicating whether API is allowed to call subsequent calls to self",
    //     "schema": {
    //         "type": "boolean",
    //         "default": false
    //     }
    // },
    {
        "name": "template",
        "in": "query",
        "description": "Internal parameter, allowing to set a specific DocumentStructure to adhere to",
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "metadata_level",
        "in": "query",
        "description": "Indicates the level of metadata to include in the response",
        "schema": {
            "type": "integer",
            "minimum": 0,
            "maximum": 2
        }
    },
    {
        "name": "technical_level",
        "in": "query",
        "description": "Indicates the level of Technical analysis to include in the content. Possible values - 0 - turn off all technical analysis content. 1 - include interesting technical analysis aspects throughout the article.",
        "schema": {
            "type": "integer",
            "minimum": 0,
            "default": 0,
            "maximum": 1
        }
    },
    {
        "name": "fundamental_level",
        "in": "query",
        "description": "Indicates the level of Fundamental analysis to include in the content. Possible values - 0 - turn off all fundamental analysis content. 1 - include interesting fundamental analysis aspects throughout the article.",
        "schema": {
            "type": "integer",
            "minimum": 0,
            "default": 0,
            "maximum": 1
        }
    },
    {
        "name": "separate_header",
        "in": "query",
        "description": "Indicates whether the header will be separated from the body in the response",
        "schema": {
            "type": "boolean",
            "default": false
        }
    },
    {
        "name": "doc_group",
        "in": "query",
        "description": "Indicates a specific Doc Structure Group to base content on",
        "required": true,
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "enable_cache",
        "in": "query",
        "description": "Indicates whether to use cached data and content - this will use data that is not necessarily the latest available",
        "schema": {
            "type": "boolean",
            "default": true
        }
    },
    {
        "name": "article_cache_ttl",
        "in": "query",
        "description": "Specifies the amount of time an article will remain cached for (In seconds). \n" +
            "Important: Article Cache is created for each unique API call, including query params. Each different request will create a different cache entry.\n" +
            "This param only works when enable_cache is set to true (true by default)",
        "schema": {
            "type": "integer",
            "default": 15
        }
    },
    {
        "name": "enable_html",
        "in": "query",
        "description": "Indicates whether to return results in HTML format (Recommended) or as a clean, un-formatted text",
        "schema": {
            "type": "boolean",
            "default": true
        }
    },
    {
        "name": "preferred_data_source",
        "in": "query",
        "description": "Specifies a preferred base data source (for available options please contact support). The supplied source supplements data and any missing data uses the default source as a fallback to ensure content can be generated.",
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "use_customer_assets",
        "in": "query",
        "description": "If true, the generated content will ONLY include assets from the assets specified as customer_assets, this is a list of approved assets that can be controlled via the dashboard by any user with Editor permissions. This includes any related assets that might be mentioned throughout the article.",
        "schema": {
            "type": "boolean",
            "default": false
        }
    },
    {
        "name": "twitter_mode",
        "in": "query",
        "description": "When twitter_enabled is set to true, this sets the HTML output for the embedded tweets.\n" +
            "twitter_widget will follow the twitter guide: https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference .\n" +
            "wordpress_plugin is used with the twitter wordpress plugin: https://blog.twitter.com/developer/en_us/a/2015/twitter-plugin-wordpress.html",
        "schema": {
            "type": "string",
            "enum": [
                'wordpress_plugin',
                'twitter_widget'
            ]
        }
    }
]


export const filtersQueryParams = [
    {
        "name": "filters",
        "in": "query",
        "description": "The asset filter requires a special format. See the <b><a href='/public#tag/Using-Filters'>filter guide</a></b> for full options and examples.",
        "schema": {
            "type": "string",
        }
    },
    {
        "name": "use_full_screener_results",
        "in": "query",
        "description": "When set to 'true', metadata results will include all applicable assets under the given set of filters. Otherwise, only assets that appear in the article will also appear in the metadata.",
        "schema": {
            "type": "bool",
            "default": false
        }
    },
]


export const financialContentQueryParams = joinedQueryParams.concat([
    {
        "name": "asset",
        "in": "path",
        "required": true,
        "description": "Choose target asset, multiple assets are supported by chaining asset symbols with a comma i.e: EURUSD,GBPUSD",
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "length",
        "in": "query",
        "description": "Choose target length for the generated article",
        "schema": {
            "type": "integer",
            "minimum": 1,
            "default": 5,
            "maximum": 10
        }
    },
    {
        "name": "images_enabled",
        "in": "query",
        "description": "When enabled, a new key-value pair will be added to the response: image_url: SOME_URL_STRING The chosen image will be the one most relevant to the generated content",
        "schema": {
            "type": "boolean",
            "default": false
        }
    },
    // {
    //     "name": "gec_enabled",
    //     "in": "query",
    //     "description": "Indicates whether to enable a Neural-network based Grammar Error Correction. This additional stage increases the response time significantly.",
    //     "schema": {
    //         "type": "boolean",
    //         "default": false
    //     }
    // },
]);

export const portfolioContentQueryParams = joinedQueryParams.concat([]);

export const watchlistContentQueryParams = joinedQueryParams.concat([
    {
        "name": "asset",
        "in": "path",
        "required": true,
        "description": "Choose target asset, multiple assets are supported by chaining asset symbols with a comma i.e: EURUSD,GBPUSD",
        "schema": {
            "type": "string"
        }
    },
]);

export const watchlistByWatchlistNameContentQueryParams = joinedQueryParams.concat([
    {
        "name": "user_id",
        "in": "path",
        "required": true,
        "description": "Chosen User ID UUID i.e: c6f7eb2a-1872-48e6-b1e6-837bb77a166e",
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "watchlist_name",
        "in": "path",
        "required": true,
        "description": "Chosen watchlist name, each user has a default watchlist called 'default'",
        "schema": {
            "type": "string"
        }
    },
]);

export const marketResearchQueryParams = joinedQueryParams.concat(filtersQueryParams)

export const authDescription = `
All HTTP requests made against the hoopsAI API must be validated with an API Key. If you don't have an API Key yet please contact support@hoopsai.com to register for one.</br></br>
**Using Your API Key**
    
You may use any server side programming language that can make HTTP requests to target the API. All requests should target domain https://api.hoopsai.com/prod.
    
API Key should be supplied in REST API calls via a custom header named x-api-key.</br></br>
**API Key Usage Credits**
    
API plans include a monthly limit or "hard cap" to the number of data calls that can be made. This usage is tracked as API "call credits" which are incremented 1:1 against successful (HTTP Status 200) data calls made with your key.
Please contact the support if the limit has been reached and you require an additional cap.
`;

export const standardsDescription = `
Each HTTP request must contain the header Accept: application/json. You should also send an Accept-Encoding: deflate, gzip header to receive data fast and efficiently.</br></br>

**Endpoint Response Payload Format**

All endpoints return data in JSON format with the results of your query under 'result' if the call is successful:

    {
        "success": true,
        "result": {
            ...
        },
        "code": 0,
        "message": "string"
    }

Status is always included for both successful calls and failures when possible.</br></br>

**Asset Identifiers**</br></br>
All Assets should be identified in endpoints using the assets's symbol (eg. symbol=EURUSD for Euro). For a current list of supported assets use the /assets api. 
`;


export const bestPracticesDescription = `
Content requests take a long time compared to a typical API since the system doesn't just retrieve data, it runs a complete pipeline for each call.
Expect a few seconds for a typical request, and up to 30 seconds for complicated requests (i.e. multiple assets)</br></br>

When calling multiple assets, the current suggested limit is 3 assets per call, although this is not enforced by the API. Exceeding this might lead to a Timeout Error.</br></br>

**Suggested Query Params**</br></br>
The full list of query params is documented for each API path and they are all optional. However, best practice should usually include:
* doc_group - to specify the group of document strcutures the content will be chosen from
* gec_enabled - set to true, this adds a neural-network grammar correction stage which adds a bit of time for the request length.
`;

export const requestBodies = {
    "addSymbolsToWatchlist": {
        "content": {
            "application/json": {
                "schema": {
                    "type": "object",
                    "properties": {
                        "symbol_list": {
                            "type": "string",
                            "description": "Symbols should be comma-seperated without spaces. Valid symbols are only the ones listed in the assets API",
                        }
                    }
                }
            }
        },
        "description": "Request Body",
        "required": true
    },
    "createWatchlist": {
        "content": {
            "application/json": {
                "schema": {
                    "type": "object",
                    "properties": {
                        "watchlist_name": {
                            "type": "string",
                            "description": "Given name to the watchlist, duplicated values aren't allowed, 'default' always exists",
                        }
                    }
                }
            }
        },
        "description": "Request Body",
        "required": true
    },
    "portfolio": {
        "content": {
            "application/json": {
                "schema": {
                    "type": "object",
                    "properties": {
                        "portfolio": {
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "symbol": {
                                        "description": "Asset symbol",
                                        "type": "string"
                                    },
                                    "num_of_shares": {
                                        "description": "(Optional) Total number of shares of the given asset",
                                        "type": "integer",
                                        "default": 1
                                    },
                                    "avg_purchase_price": {
                                        "description": "(Optional) Average purchase price of the given asset",
                                        "type": "number",
                                        "default": 1
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        "description": "Portfolio Request Body",
        "required": true
    },
    "journal": {
        "content": {
            "application/json": {
                "schema": {
                    "type": "object",
                    "properties": {
                        "hrtf": {
                            "description": "Game content in HRTF (HoopsAI Rich Text Format) format",
                            "type": "string"
                        },
                        "text": {
                            "description": "Game content in text format",
                            "type": "string"
                        },
                        "properties": {
                            "description": "List of properties used to generate the original content",
                            "type": "array"
                        }
                    }
                }
            }
        },
        "description": "Journal Request Body",
        "required": true
    },
}
