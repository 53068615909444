const joinedQueryParams = [
    {
        "name": "metadata_level",
        "in": "query",
        "description": "Indicates the level of metadata to include in the response",
        "schema": {
            "type": "integer",
            "minimum": 0,
            "maximum": 2
        }
    },
    {
        "name": "technical_level",
        "in": "query",
        "description": "Indicates the level of Technical analysis to include in the content. Possible values - 0 - turn off all technical analysis content. 1 - include interesting technical analysis aspects throughout the article.",
        "schema": {
            "type": "integer",
            "minimum": 0,
            "default": 0,
            "maximum": 1
        }
    },
    {
        "name": "separate_header",
        "in": "query",
        "description": "Indicates whether the header will be separated from the body in the response",
        "schema": {
            "type": "boolean",
            "default": false
        }
    },
    {
        "name": "preferred_data_source",
        "in": "query",
        "description": "Specifies a preferred base data source (for available options please contact support). The supplied source supplements data and any missing data uses the default source as a fallback to ensure content can be generated.",
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "use_customer_assets",
        "in": "query",
        "description": "If true, the generated content will ONLY include assets from the assets specified as customer_assets, this is a list of approved assets that can be controlled via the dashboard by any user with Editor permissions. This includes any related assets that might be mentioned throughout the article.",
        "schema": {
            "type": "boolean",
            "default": false
        }
    },
]


export const filtersQueryParams = [
    {
        "name": "filters",
        "in": "query",
        "description": "The asset filter requires a special format. See the <b><a href='/#tag/Filters'>filter guide</a></b> for full options and examples.",
        "schema": {
            "type": "string",
        }
    },
    {
        "name": "sort_key",
        "in": "query",
        "description": "The sort_key parameter defines how the results are ordered, such as by price changes or volume. See the <b><a href='/#tag/Sort-Keys'>filter guide</a></b> for full options and examples.",
        "schema": {
            "type": "string",
        },
    },
    {
        "name": "filter_scope",
        "in": "query",
        "description": "The filter_scope parameter allows you to specify the scope of data returned for assets, focusing on specific aspects like valuation or performance. See the <b><a href='/#tag/Scopes'>filter guide</a></b> for full options and examples.",
        "schema": {
            "type": "string",
            "enum": [
                "overview_scope",
                "performance_scope",
                "valuation_scope",
                "income_statement_scope",
                "balance_sheet_scope",
                "cash_flow_scope",
                "technical_scope",
                "earnings_scope",
            ]
        },
    },
    {
        "name": "page",
        "in": "query",
        "description": "Page number for paginated results",
        "schema": {
            "type": "integer",
            "minimum": 1,
        },
    },
    {
        "name": "size",
        "in": "query",
        "description": "Page size - Number of results per page",
        "schema": {
            "type": "integer",
            "minimum": 1,
            "maximum": 100,
        },
    },
]


export const basicFiltersQueryParams = [
    {
        "name": "filters",
        "in": "query",
        "description": "The asset filter requires a special format. See the <b><a href='/#tag/Filters'>filter guide</a></b> for full options and examples.",
        "schema": {
            "type": "string",
        }
    },
]


export const financialContentQueryParams = joinedQueryParams.concat([
    {
        "name": "asset",
        "in": "path",
        "required": true,
        "description": "Choose target asset, multiple assets are supported by chaining asset symbols with a comma i.e: EURUSD,GBPUSD",
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "length",
        "in": "query",
        "description": "Choose target length for the generated article",
        "schema": {
            "type": "integer",
            "minimum": 1,
            "default": 5,
            "maximum": 10
        }
    },
]);


export const watchlistContentQueryParams = joinedQueryParams.concat([
    {
        "name": "asset",
        "in": "path",
        "required": true,
        "description": "Choose target asset, multiple assets are supported by chaining asset symbols with a comma i.e: EURUSD,GBPUSD",
        "schema": {
            "type": "string"
        }
    },
]);

export const watchlistByWatchlistNameContentQueryParams = joinedQueryParams.concat([
    {
        "name": "user_id",
        "in": "path",
        "required": true,
        "description": "Chosen User ID UUID i.e: c6f7eb2a-1872-48e6-b1e6-837bb77a166e",
        "schema": {
            "type": "string"
        }
    },
    {
        "name": "watchlist_name",
        "in": "path",
        "required": true,
        "description": "Chosen watchlist name, each user has a default watchlist called 'default'",
        "schema": {
            "type": "string"
        }
    },
]);

export const marketResearchQueryParams = joinedQueryParams.concat(basicFiltersQueryParams)

export const authDescription = `
All HTTP requests made against the hoopsAI API must be validated with an API Key. If you don't have an API Key yet please contact support@hoopsai.com to register for one.</br></br>
**Using Your API Key**
    
You may use any server side programming language that can make HTTP requests to target the API. All requests should target domain https://api.hoopsai.com/prod.
    
API Key should be supplied in REST API calls via a custom header named x-api-key.</br></br>
**API Key Usage Credits**
    
API plans include a monthly limit or "hard cap" to the number of data calls that can be made. This usage is tracked as API "call credits" which are incremented 1:1 against successful (HTTP Status 200) data calls made with your key.
Please contact the support if the limit has been reached and you require an additional cap.
`;

export const standardsDescription = `
Each HTTP request must contain the header Accept: application/json. You should also send an Accept-Encoding: deflate, gzip header to receive data fast and efficiently.</br></br>

**Endpoint Response Payload Format**

All endpoints return data in JSON format with the results of your query under 'result' if the call is successful:

    {
        "success": true,
        "result": {
            ...
        },
        "code": 0,
        "message": "string"
    }

Status is always included for both successful calls and failures when possible.</br></br>

**Asset Identifiers**</br></br>
All Assets should be identified in endpoints using the assets's symbol (eg. symbol=EURUSD for Euro). For a current list of supported assets use the /assets api. 
`;


export const bestPracticesDescription = `
Content requests take a long time compared to a typical API since the system doesn't just retrieve data, it runs a complete pipeline for each call.
Expect a few seconds for a typical request, and up to 30 seconds for complicated requests (i.e. multiple assets)</br></br>

When calling multiple assets, the current suggested limit is 3 assets per call, although this is not enforced by the API. Exceeding this might lead to a Timeout Error.</br></br>
`;

export const requestBodies = {
    "addSymbolsToWatchlist": {
        "content": {
            "application/json": {
                "schema": {
                    "type": "object",
                    "properties": {
                        "symbol_list": {
                            "type": "string",
                            "description": "Symbols should be comma-seperated without spaces. Valid symbols are only the ones listed in the assets API",
                        }
                    }
                }
            }
        },
        "description": "Request Body",
        "required": true
    },
    "createWatchlist": {
        "content": {
            "application/json": {
                "schema": {
                    "type": "object",
                    "properties": {
                        "watchlist_name": {
                            "type": "string",
                            "description": "Given name to the watchlist, duplicated values aren't allowed, 'default' always exists",
                        }
                    }
                }
            }
        },
        "description": "Request Body",
        "required": true
    },

}
