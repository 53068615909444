import React from 'react';
import {RedocStandalone} from 'redoc';
import {spec} from './spec'


function Documentation() {
    return (
            <RedocStandalone
                spec={spec}
                onLoaded={error => {
                    if (!error) {
                        console.log('Yay!');
                    }
                }}
                options={{
                    noAutoAuth: true,
                    pathInMiddlePanel: true,
                    theme: { logo: { gutter: '32px' } },
                }}
            />
    );
}

export default Documentation;
