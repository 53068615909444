import {marketResearchQueryParams} from './specSnippets';

export const articleSnippet = (summary, opId, description, endpoint) => {
    return {
        "get": {
            "tags": [
                "Market Research"
            ],
            "summary": summary,
            "operationId": opId,
            "description": description,
            "parameters": marketResearchQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/FinanceContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        `conn.request("GET", "${endpoint}", None, headers)\n` +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    }
}
