export const userSpecPaths = {
    "/api/v1/users/{customer}": {
        "get": {
            "tags": [
                "Users"
            ],
            "description": "Return the list of users for the given customer \n\n Authorization is done via api key and access to other customers is forbidden",
            "operationId": "getUsers",
            "summary": "Get Users By Customer",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/GetUsersResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/users/{}\".format(customer), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
        "post": {
            "tags": [
                "Users"
            ],
            "description": "Create a new user for the given customer. Users are identifiable only through a UUID. Upon creation, a default empty watchlist is also created for the new user.",
            "summary": "Create User",
            "operationId": "createUser",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CreateUsersResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client, urllib.parse\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"POST\", \"/prod/api/v1/users/{}\".format(customer), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/users/{customer}/{user-id}": {
        "delete": {
            "tags": [
                "Users"
            ],
            "description": "Delete the user and its associated watchlists by the requested user id.",
            "summary": "Delete User By User ID",
            "operationId": "deleteUser",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/DeleteUsersResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'TARGET_USER_ID'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"DELETE\", \"/prod/api/v1/users/{}/{}\".format(customer, user_id), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
    },
    "/api/v1/users/{customer}/{user-id}/watchlists": {
        "get": {
            "tags": [
                "Users"
            ],
            "description": "Return the list of watchlists and their included symbols for the requested user",
            "operationId": "getWatchlists",
            "summary": "Get Watchlists By User",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/GetWatchlistResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'USER_UUID'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/users/{}/{}/watchlists\".format(customer, user_id), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
        "post": {
            "tags": [
                "Users"
            ],
            "description": "Create a new watchlist for the requested user using the watchlist_name body param.",
            "summary": "Create Watchlist For User",
            "operationId": "createWatchlist",
            "requestBody": {
                "$ref": '#/components/requestBodies/createWatchlist'
            },
            "parameters": [
                {
                    "name": "watchlist_name",
                    "in": "path",
                    "required": true,
                    "schema": {
                        "type": "string"
                    }
                }
            ],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CreateWatchlistResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client, urllib.parse\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'USER_UUID'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "params = {\"watchlist_name\": \"TEST_WATCHLIST_NAME\"}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"POST\", \"/prod/api/v1/users/{}/{}/watchlists\".format(customer, user_id), json.dumps(params), headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/users/{customer}/{user-id}/watchlists/{watchlist-name}": {
        "get": {
            "tags": [
                "Users"
            ],
            "description": "Return the watchlist and its included symbols for the requested watchlist name",
            "operationId": "getWatchlistByName",
            "summary": "Get Watchlist By Name",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/GetWatchlistByNameResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'USER_UUID'\n" +
                        "watchlist = 'WATCHLIST_NAME'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/users/{}/{}/watchlists/{}\".format(customer, user_id, watchlist), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
        "post": {
            "tags": [
                "Users"
            ],
            "description": "Add multiple symbols to watchlist using the requested watchlist name and the symbol_list body param.",
            "summary": "Add Symbols To Watchlist",
            "operationId": "addSymbolsToWatchlist",
            "requestBody": {
                "$ref": '#/components/requestBodies/addSymbolsToWatchlist'
            },
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/AddSymbolsToWatchlistResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client, urllib.parse\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'USER_UUID'\n" +
                        "watchlist = 'WATCHLIST_NAME'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "params = {\"symbol_list\": \"AAPL,FB\"}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"POST\", \"/prod/api/v1/users/{}/{}/watchlists/{}\".format(customer, user_id, watchlist), json.dumps(params), headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
        "delete": {
            "tags": [
                "Users"
            ],
            "description": "Delete the requested watchlist from the requested user",
            "operationId": "deleteWatchlistByName",
            "summary": "Delete Watchlist By Name",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/DeleteWatchlistByNameResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'USER_UUID'\n" +
                        "watchlist = 'WATCHLIST_NAME'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"DELETE\", \"/prod/api/v1/users/{}/{}/watchlists/{}\".format(customer, user_id, watchlist), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
    },
    "/api/v1/users/{customer}/{user-id}/watchlists/{watchlist-name}/{symbol}": {
        "post": {
            "tags": [
                "Users"
            ],
            "description": "Add symbol to watchlist using the requested watchlist name symbol.",
            "summary": "Add Symbol To Watchlist",
            "operationId": "addSymbolToWatchlist",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/AddSymbolToWatchlistResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client, urllib.parse\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'USER_UUID'\n" +
                        "watchlist = 'WATCHLIST_NAME'\n" +
                        "symbol = 'TARGET_SYMBOL'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"POST\", \"/prod/api/v1/users/{}/{}/watchlists/{}/{}\".format(customer, user_id, watchlist, symbol), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
        "delete": {
            "tags": [
                "Users"
            ],
            "description": "Remove the requested symbol from the requested watchlist",
            "operationId": "removeSymbolFromWatchlist",
            "summary": "Remove Symbol From Watchlist",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/RemoveSymbolFromWatchlistResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'USER_UUID'\n" +
                        "watchlist = 'WATCHLIST_NAME'\n" +
                        "symbol = 'TARGET_SYMBOL'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"DELETE\", \"/prod/api/v1/users/{}/{}/watchlists/{}/{}\".format(customer, user_id, watchlist, symbol), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/users/{customer}/{user-id}/watchlists/{watchlist-name}/rename/{new-watchlist-name}": {
        "put": {
            "tags": [
                "Users"
            ],
            "description": "Rename watchlist. \n\n watchlist names cannot include spaces and 'default' watchlist cannot be renamed.",
            "summary": "Rename Watchlist",
            "operationId": "renameWatchlist",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/RenameWatchlistResponse"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client, urllib.parse\n" +
                        "import json\n" +
                        "\n" +
                        "customer = 'YOUR_CUSTOMER_NAME'\n" +
                        "user_id = 'USER_UUID'\n" +
                        "watchlist = 'WATCHLIST_NAME'\n" +
                        "new_watchlist_name = 'NEW_WATCHLIST_NAME'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"PUT\", \"/prod/api/v1/users/{}/{}/watchlists/{}/rename/{}\".format(customer, user_id, watchlist, new_watchlist_name), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    }
}
