import React from 'react';
import './App.css';
import ButtonAppBar from './AppBar'
import Routes from './Routes'
import {withRouter} from "react-router-dom";
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Nunito Sans',
            '"Helvetica Neue"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(',')
    }
});

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                <ButtonAppBar />
                <Routes />
            </ThemeProvider>
        </div>
    );
}

export default withRouter(App);
