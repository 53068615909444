import {
    filtersQueryParams,
    financialContentQueryParams,
    portfolioContentQueryParams,
    watchlistByWatchlistNameContentQueryParams,
    watchlistContentQueryParams
} from './specSnippets';
import {userSpecPaths} from "./userSpecPaths";
import {sportSpecPaths} from "./sportSpecPaths";
import {articleSnippet} from "./articleSnippets";


export const specPaths = {
    "/api/v1/resources/finance/daily/{asset+}": {
        "get": {
            "tags": [
                "Asset Content & Analysis"
            ],
            "summary": "daily",
            "operationId": "daily",
            "description": "Get near real-time content within the context of the last trading day for the selected asset symbol",
            "parameters": financialContentQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "oneOf": [
                                    {"$ref": "#/components/schemas/FinanceContent"},
                                    {"$ref": "#/components/schemas/MultipleFinanceContent"},
                                ]
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "asset = TARGET_ASSET\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/finance/daily/{}\".format(asset), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    // "/api/v1/resources/finance/hourly/{asset+}": {
    //     "get": {
    //         "tags": [
    //             "Content"
    //         ],
    //         "summary": "hourly",
    //         "operationId": "hourly",
    //         "description": "Experimental - Get near real-time content within the context of the last trading hour for the selected asset symbol",
    //         "parameters": financialContentQueryParams,
    //         "responses": {
    //             "200": {
    //                 "description": "200 response",
    //                 "content": {
    //                     "application/json": {
    //                         "schema": {
    //                             "oneOf": [
    //                                 {"$ref": "#/components/schemas/FinanceContent"},
    //                                 {"$ref": "#/components/schemas/MultipleFinanceContent"},
    //                             ]
    //                         }
    //                     }
    //                 }
    //             },
    //             "400": {
    //                 "description": "Server Error"
    //             },
    //             "403": {
    //                 "description": "Validation exception"
    //             }
    //         },
    //         "security": [
    //             {
    //                 "api_key": []
    //             }
    //         ],
    //         "x-code-samples": [
    //             {
    //                 "lang": "Python",
    //                 "source": "import http.client\n" +
    //                     "import json\n" +
    //                     "\n" +
    //                     "asset = TARGET_ASSET\n" +
    //                     "api_key = YOUR_API_KEY\n" +
    //                     "headers = {'x-api-key': api_key}\n" +
    //                     "\n" +
    //                     "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
    //                     "\n" +
    //                     "conn.request(\"GET\", \"/prod/api/v1/resources/finance/hourly/{}\".format(asset), None, headers)\n" +
    //                     "\n" +
    //                     "res = conn.getresponse()\n" +
    //                     "data = json.loads(res.read())\n"
    //             }
    //         ]
    //     }
    // },
    "/api/v1/resources/finance/weekly/{asset+}": {
        "get": {
            "tags": [
                "Asset Content & Analysis"
            ],
            "summary": "weekly",
            "operationId": "weekly",
            "description": "Experimental - Get near real-time content within the context of the last week for the selected asset symbol",
            "parameters": financialContentQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "oneOf": [
                                    {"$ref": "#/components/schemas/FinanceContent"},
                                    {"$ref": "#/components/schemas/MultipleFinanceContent"},
                                ]
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "asset = TARGET_ASSET\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/finance/weekly/{}\".format(asset), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/finance/article/portfolio": {
        "post": {
            "tags": [
                "Personalized Content & Analysis"
            ],
            "summary": "Portfolio",
            "operationId": "portfolio",
            "description": "BETA - Get personalized content about a supplied portfolio.",
            "requestBody": {
                "$ref": '#/components/requestBodies/portfolio'
            },
            "parameters": portfolioContentQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/FinanceContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client, urllib.parse\n" +
                        "import json\n" +
                        "\n" +
                        "asset = TARGET_ASSET\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "params = {\n\"portfolio\": [\n" +
                        "   {\"symbol\": \"ADBE\", \"num_of_shares\": 1, \"avg_purchase_price\": 100},\n" +
                        "   {\"symbol\": \"ADSK\", \"num_of_shares\": 1, \"avg_purchase_price\": 100}\n" +
                        "]}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"POST\", \"/prod/api/v1/resources/finance/article/portfolio\", params, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/finance/article/watchlist": {
        "get": {
            "tags": [
                "Personalized Content & Analysis"
            ],
            "summary": "Watchlist",
            "operationId": "watchlist",
            "description": "BETA - Get unique content about a supplied watchlist.",
            "parameters": watchlistContentQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/FinanceContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "asset = 'TARGET_ASSET_A,TARGET_ASSET_B'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/finance/article/watchlist/{}\".format(asset), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/finance/article/watchlist/{user_id}/{watchlist_name}": {
        "get": {
            "tags": [
                "Personalized Content & Analysis"
            ],
            "summary": "Get Watchlist by User ID & Watchlist Name",
            "operationId": "get_watchlist_by_user_watchlist_name",
            "description": "BETA - Get unique content by a user's ID and watchlist name.",
            "parameters": watchlistByWatchlistNameContentQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/FinanceContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "user_id = 'c6f7eb2a-1872-48e6-b1e6-837bb77a166e'\n" +
                        "watchlist_name = 'default'\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/finance/article/watchlist/{}/{}\".format(user_id, watchlist_name), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/finance/article/top_gainers": articleSnippet(
        'Top Gainers',
        'top_gainers',
        "Generate an article covering the top gaining assets of the latest trading session.",
        '/prod/api/v1/resources/finance/article/top_gainers'
    ),
    "/api/v1/resources/finance/article/top_losers": articleSnippet(
        'Top Losers',
        'top_losers',
        "Generate an article covering the top losing assets of the latest trading session.",
        '/prod/api/v1/resources/finance/article/top_losers'
    ),
    "/api/v1/resources/finance/article/top_movers": articleSnippet(
        'Top Movers',
        'top_movers',
        "Generate an article covering the top moving assets (Gaining or losing) of the latest trading session.",
        '/prod/api/v1/resources/finance/article/top_movers'
    ),
    "/api/v1/resources/finance/article/down_trend": articleSnippet(
        'Down Trend',
        'down_trend',
        "Generate an article covering assets that have been on a short term (from several days to weeks) down trend.",
        '/prod/api/v1/resources/finance/article/down_trend'
    ),
    "/api/v1/resources/finance/article/up_trend": articleSnippet(
        'Up Trend',
        'up_trend',
        "Generate an article covering assets that have been on a short term (from several days to weeks) up trend.",
        '/prod/api/v1/resources/finance/article/up_trend'
    ),
    "/api/v1/resources/finance/article/market_overview": articleSnippet(
        'Market Overview',
        'market_overview',
        "Generate an article covering the top news of the major indices and markets for the latest trading session.",
        '/prod/api/v1/resources/finance/article/market_overview'
    ),
    "/api/v1/resources/finance/article/price_target": articleSnippet(
        'Price Target',
        'price_target',
        "Generate an article covering assets reaching or crossing important price levels in real-time.",
        '/prod/api/v1/resources/finance/article/price_target'
    ),
    "/api/v1/resources/finance/article/upcoming_earnings": articleSnippet(
        'Upcoming Earnings',
        'upcoming_earnings',
        "Generate an article covering the assets that are scheduled to publish quarterly earning reports in the upcoming 30 days",
        '/prod/api/v1/resources/finance/article/recent_earnings'
    ),
    "/api/v1/resources/finance/article/recent_earnings": articleSnippet(
        'Recent Earnings',
        'recent_earnings',
        "Generate an article covering the assets that recently published quarterly earning reports with real time market updates..",
        '/prod/api/v1/resources/finance/article/recent_earnings'
    ),
    "/api/v1/resources/finance/article/record_high": articleSnippet(
        'Record High',
        'record_high',
        "Generate an article covering assets that reached record highs using real time markets data.",
        '/prod/api/v1/resources/finance/article/record_high'
    ),
    "/api/v1/resources/finance/article/unusual_high_volume": articleSnippet(
        'Unusual High Volume',
        'unusual_high_volume',
        "Generate an article covering assets showing unusually high volume in the current (or last) trading session.",
        '/prod/api/v1/resources/finance/article/unusual_high_volume'
    ),
    "/api/v1/resources/finance/assets": {
        "get": {
            "tags": [
                "Data"
            ],
            "summary": "assets",
            "operationId": "assets",
            "description": "Get list of all available financial assets and their properties",
            "parameters": filtersQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Assets"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                }
            },
            "security": [
                'None'
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/finance/customer_assets\"\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/finance/customer_assets": {
        "get": {
            "tags": [
                "Data"
            ],
            "summary": "Customer Assets",
            "operationId": "customer_assets",
            "description": "Get list of all financial assets and their properties under the configuration of the selected customer.",
            "parameters": filtersQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Assets"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/finance/assets\"\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    ...userSpecPaths,
    ...sportSpecPaths
}
