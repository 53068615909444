export const responseSchemas = {
    "Empty": {
        "title": "Empty Schema",
        "type": "object"
    },
    "Content": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {
                "type": "object",
                "properties": {
                    "content": {
                        "description": "Generated content in JSON format built using HRTF (HoopsAI Rich Text Format) notation",
                        "type": "string"
                    },
                    "text": {
                        "description": "Generated content in html format",
                        "type": "string"
                    },
                    "metadata": {
                        "type": "object",
                        "properties": {
                            "generation_utc_date": {
                                "description": "Timestamp for the content generation (%Y-%m-%d %H:%M:%S)",
                                "type": "string"
                            },
                            "generation_run_time": {
                                "description": "Value holds the time in seconds that took to generate the content",
                                "type": "number"
                            },
                            "doc_structure": {
                                "description": "Internal value to specify the chosen Document Structure",
                                "type": "string"
                            },
                            "doc_structure_score": {
                                "description": "Internal value to specify the chosen Document Structure score",
                                "type": "number"
                            }
                        }
                    }
                }
            },
            "code": {
                "description": "Code status",
                "type": "integer"
            },
            "message": {
                "description": "Additional request messages",
                "type": "string"
            }
        }
    },
    "MultipleFinanceContent": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {
                "type": "object",
                "properties": {
                    "EURUSD": {
                        "type": "object",
                        "properties": {
                            "content": {
                                "description": "Generated content in JSON format built using HRTF (HoopsAI Rich Text Format) notation",
                                "type": "string"
                            },
                            "text": {
                                "description": "Generated content in html format",
                                "type": "string"
                            },
                            "image_url": {
                                "description": "OPTIONAL - Only added when images_enabled query param is set to true",
                                "type": "string"
                            },
                            "metadata": {
                                "type": "object",
                                "properties": {
                                    "generation_utc_date": {
                                        "description": "Timestamp for the content generation (%Y-%m-%d %H:%M:%S)",
                                        "type": "string"
                                    },
                                    "generation_run_time": {
                                        "description": "Value holds the time in seconds that took to generate the content",
                                        "type": "number"
                                    },
                                    "doc_structure": {
                                        "description": "Internal value to specify the chosen Document Structure",
                                        "type": "string"
                                    },
                                    "doc_structure_score": {
                                        "description": "Internal value to specify the chosen Document Structure score",
                                        "type": "number"
                                    },
                                    "asset_quote": {
                                        "type": "object",
                                        "properties": {
                                            "timestamp": {
                                                "description": "Timestamp of the event time of reference",
                                                "type": "string"
                                            },
                                            "open": {
                                                "description": "Open rate of selected asset",
                                                "type": "number"
                                            },
                                            "previous_close": {
                                                "description": "Previous close rate of selected asset",
                                                "type": "number"
                                            },
                                            "close": {
                                                "description": "Close rate of selected asset",
                                                "type": "number"
                                            },
                                            "high": {
                                                "description": "High rate of selected asset",
                                                "type": "number"
                                            },
                                            "low": {
                                                "description": "Low rate of selected asset",
                                                "type": "number"
                                            }
                                        }
                                    },
                                    "asset_ticks": {
                                        "type": "array",
                                        "items": {
                                            "type": "array",
                                            "items": {
                                                "tick_timestamp": {
                                                    "description": "Tick timestamp",
                                                    "type": "string"
                                                },
                                                "price": {
                                                    "description": "Asset price",
                                                    "type": "number"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "GBPUSD": {
                        "type": "object",
                        "properties": {
                            "content": {
                                "description": "Generated content in JSON format built using HRTF (HoopsAI Rich Text Format) notation",
                                "type": "string"
                            },
                            "text": {
                                "description": "Generated content in html format",
                                "type": "string"
                            },
                            "image_url": {
                                "description": "OPTIONAL - Only added when images_enabled query param is set to true",
                                "type": "string"
                            },
                            "metadata": {
                                "type": "object",
                                "properties": {
                                    "generation_utc_date": {
                                        "description": "Timestamp for the content generation (%Y-%m-%d %H:%M:%S)",
                                        "type": "string"
                                    },
                                    "generation_run_time": {
                                        "description": "Value holds the time in seconds that took to generate the content",
                                        "type": "number"
                                    },
                                    "doc_structure": {
                                        "description": "Internal value to specify the chosen Document Structure",
                                        "type": "string"
                                    },
                                    "doc_structure_score": {
                                        "description": "Internal value to specify the chosen Document Structure score",
                                        "type": "number"
                                    },
                                    "asset_quote": {
                                        "type": "object",
                                        "properties": {
                                            "timestamp": {
                                                "description": "Timestamp of the event time of reference",
                                                "type": "string"
                                            },
                                            "open": {
                                                "description": "Open rate of selected asset",
                                                "type": "number"
                                            },
                                            "previous_close": {
                                                "description": "Previous close rate of selected asset",
                                                "type": "number"
                                            },
                                            "close": {
                                                "description": "Close rate of selected asset",
                                                "type": "number"
                                            },
                                            "high": {
                                                "description": "High rate of selected asset",
                                                "type": "number"
                                            },
                                            "low": {
                                                "description": "Low rate of selected asset",
                                                "type": "number"
                                            }
                                        }
                                    },
                                    "asset_ticks": {
                                        "type": "array",
                                        "items": {
                                            "type": "array",
                                            "items": {
                                                "tick_timestamp": {
                                                    "description": "Tick timestamp",
                                                    "type": "string"
                                                },
                                                "price": {
                                                    "description": "Asset price",
                                                    "type": "number"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "code": {
                "description": "Code status",
                "type": "integer"
            },
            "message": {
                "description": "Additional request messages",
                "type": "string"
            }
        }
    },
    "Assets": {
        "type": "object",
        "properties": {
            "assets": {
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": {
                        "asset_id": {
                            "description": "Asset UUID",
                            "type": "string"
                        },
                        "currency": {
                            "description": "Currency the asset is associated with",
                            "type": "string"
                        },
                        "asset_class": {
                            "description": "Asset Class",
                            "type": "string"
                        },
                        "symbol": {
                            "description": "Unique Symbol of the asset",
                            "type": "string"
                        },
                        "stock_exchange": {
                            "description": "Name of the stock exchange the asset is associated with",
                            "type": "string"
                        }
                    }
                }
            },
            "total": {
                "description": "Total number of items in paginated result",
                "type": "integer"
            },
            "page": {
                "description": "Current page number in paginated result",
                "type": "integer"
            },
            "size": {
                "description": "Number of items in current result in paginated result",
                "type": "integer"
            },
            "pages": {
                "description": "Total number of pages in paginated result",
                "type": "integer"
            }
        }
    },
    "FinanceContent": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {
                "type": "object",
                "properties": {
                    "content": {
                        "description": "Generated content in JSON format built using HRTF (HoopsAI Rich Text Format) notation",
                        "type": "string"
                    },
                    "text": {
                        "description": "Generated content in html format",
                        "type": "string"
                    },
                    "image_url": {
                        "description": "OPTIONAL - Only added when images_enabled query param is set to true",
                        "type": "string"
                    },
                    "metadata": {
                        "type": "object",
                        "properties": {
                            "generation_utc_date": {
                                "description": "Timestamp for the content generation (%Y-%m-%d %H:%M:%S)",
                                "type": "string"
                            },
                            "generation_run_time": {
                                "description": "Value holds the time in seconds that took to generate the content",
                                "type": "number"
                            },
                            "doc_structure": {
                                "description": "Internal value to specify the chosen Document Structure",
                                "type": "string"
                            },
                            "doc_structure_score": {
                                "description": "Internal value to specify the chosen Document Structure score",
                                "type": "number"
                            },
                            "asset_quote": {
                                "type": "object",
                                "properties": {
                                    "timestamp": {
                                        "description": "Timestamp of the event time of reference",
                                        "type": "string"
                                    },
                                    "open": {
                                        "description": "Open rate of selected asset",
                                        "type": "number"
                                    },
                                    "previous_close": {
                                        "description": "Previous close rate of selected asset",
                                        "type": "number"
                                    },
                                    "close": {
                                        "description": "Close rate of selected asset",
                                        "type": "number"
                                    },
                                    "high": {
                                        "description": "High rate of selected asset",
                                        "type": "number"
                                    },
                                    "low": {
                                        "description": "Low rate of selected asset",
                                        "type": "number"
                                    }
                                }
                            },
                            "asset_ticks": {
                                "type": "array",
                                "items": {
                                    "type": "array",
                                    "items": {
                                        "tick_timestamp": {
                                            "description": "Tick timestamp",
                                            "type": "string"
                                        },
                                        "price": {
                                            "description": "Asset price",
                                            "type": "number"
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "code": {
                "description": "Code status",
                "type": "integer"
            },
            "message": {
                "description": "Additional request messages",
                "type": "string"
            }
        }
    },
    "GetUsersResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {
                "type": "object",
                "properties": {
                    "users": {
                        "type": "array",
                        "items": {
                            "type": "string",
                            "description": "User UUID",
                        }
                    },
                }
            },
            "message": {
                "description": "Additional response details",
                "type": "string"
            }
        }
    },
    "CreateUsersResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {
                "type": "object",
                "properties": {
                    "user_id": {
                        "type": "string",
                        "description": "The new user UUID",
                    },
                    "user_watchlist_id": {
                        "type": "string",
                        "description": "The new user watchlist UUID",
                    },
                }
            },
            "message": {
                "description": "Successfully created user",
                "type": "string"
            }
        }
    },
    "DeleteUsersResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {},
            "message": {
                "description": "Successfully deleted user id and its related watchlists",
                "type": "string"
            }
        }
    },
    "GetWatchlistResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {
                "type": "object",
                "properties": {
                    "watchlists": {
                        "type": "object",
                        "properties": {
                            "default": {
                                "type": "array",
                                "items": {
                                    "description": "Watchlist asset",
                                    "type": "string"
                                }
                            },
                        }
                    },
                }
            },
            "message": {
                "description": "",
                "type": "string"
            }
        }
    },
    "CreateWatchlistResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {
                "type": "object",
                "properties": {
                    "user_watchlist_name": {
                        "type": "string",
                        "description": "The new watchlist name",
                    },
                    "user_watchlist_id": {
                        "type": "string",
                        "description": "The new watchlist UUID",
                    },
                }
            },
            "message": {
                "description": "",
                "type": "string"
            }
        }
    },
    "GetWatchlistByNameResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {
                "type": "object",
                "properties": {
                    "default": {
                        "type": "array",
                        "items": {
                            "description": "Watchlist asset",
                            "type": "string"
                        }
                    },
                }
            },
            "message": {
                "description": "",
                "type": "string"
            }
        }
    },
    "AddSymbolsToWatchlistResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {},
            "message": {
                "description": "Successfully added SYMBOL1, SYMBOL2 to user watchlist: WATCHLIST_NAME",
                "type": "string"
            }
        }
    },
    "DeleteWatchlistByNameResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {},
            "message": {
                "description": "Successfully deleted user watchlist: WATCHLIST_NAME",
                "type": "string"
            }
        }
    },
    "AddSymbolToWatchlistResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {},
            "message": {
                "description": "Successfully added SYMBOL to user watchlist: WATCHLIST_NAME",
                "type": "string"
            }
        }
    },
    "RemoveSymbolFromWatchlistResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {},
            "message": {
                "description": "Successfully removed symbol from user watchlist: default",
                "type": "string"
            }
        }
    },
    "RenameWatchlistResponse": {
        "type": "object",
        "properties": {
            "success": {
                "description": "True if request succeeded",
                "type": "boolean"
            },
            "result": {},
            "message": {
                "description": "Successfully renamed user watchlist: OLD_NAME to: NEW_NAME",
                "type": "string"
            }
        }
    },
}
