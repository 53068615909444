import React from 'react';
import Typography from '@mui/material/Typography';


function NotFound() {
    return (
        <Typography variant={'h3'} style={{margin: '64px'}}>Page Not Found</Typography>
    );
}

export default NotFound;
