export const filterSnippet = `
hoopsAI provides asset filtering. You can use the <code>?filters</code> param on any of the <b>'Market Research'</b> and <b>'Data'</b> endpoints to define which assets should be included in the response content.

When using a filter, if there are not enough assets found under the filtering conditions and the target number of assets (Default: 3) the API will return all applicable assets, closest to the target number.
  
<b>Below is a formal filter description:</b>

<ul>
  <li>Fields and values in filter are separated with <code>_</code> i.e.: <code>?filters=assetclass_currencies</code></li>
  <br/>
  <li>Multiple filters are separated with <code>,</code> (url-encode value %2C) i.e.: <code>?filters=assetclass_currencies,targetassetnumber_7</code> They will be joined with AND logic. In this example: assetclass:currencies AND targetassetnumber:7.</li>
  <br/>
  <li>You can use multiple values using <code>|</code> (url-encode value %7C) as values separator i.e.: <code>?filters=assetclass_currencies|stocks</code>. Multiple values specified for a field will be joined with OR logic. In this example: assetclass:currencies OR assetclass:stocks.</li>
</ul>

<b>Below is a complete list of current supported filters detailed by keys and possible values:</b>


<b>Asset Class <u>(Default: "stocks")</u> -</b>


    assetclass: {
        "any",
        "currencies",
        "indices",
        "stocks",
        "commodities",
        "cryptocurrency",
        "etfs"
    }

<b>Target Number of Assets (Retrieve N best matching assets) <u>(Default: 3)</u> -</b>


    targetassetnumber: {
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10
    }
    
<b>Stock Exchange (Default: Any) -</b>


    stockexchange: {
        "XNYS", // New York stock exchange
        "XNAS" // Nasdaq
    }
    
<b>Stock Index (Default: Any) -</b>


    stockindex: {
        "^GSPC", // S&P 500
        "^DJI" // Dow Jones
    }
    
<b>Stock Index (Default: Any) -</b>


    stocksector: {
        "basic_materials",
        "communication_services",
        "conglomerates",
        "consumer_cyclical",
        "consumer_defensive",
        "energy",
        "financial_services",
        "healthcare",
        "industrials",
        "real_estate",
        "technology",
        "utilities"
    }
    
    
<b>Market Cap (Default: Any) -</b>


    marketcap: {
        "mega", // Mega (over $200B)
        "large", // Large ($10B to $200B)
        "mid", // Mid ($2B to $10B)
        "small", // Small ($300M to $2B)
        "micro", // Micro ($50M to $300M)
        "largeover", // Large and over (over $10B)
        "midover", // Mid and over (over $2B)
        "smallover", // Small and over (over $300M)
        "microover", // Micro and over (over $50M)
        "largeunder", // Large and under (under $200B)
        "midunder", // Mid and under (under $10B)
        "smallunder", // Small and under (under $2B)
        "microunder" // Micro and under (under $300M)
    }
    
<b>Stock Country (Default: Any) -</b>


    stockcountry: {
        "AR", // Argentina
        "AU", // Australia
        "BE", // Belgium
        "BM", // Bermuda
        "BR", // Brazil
        "CA", // Canada
        "KY", // Cayman Islands
        "CL", // Chile
        "CN", // China
        "CO", // Colombia
        "CY", // Cyprus
        "DK", // Denmark
        "FR", // France
        "DE", // Germany
        "GB", // Great Britain (UK)
        "HK", // Hong Kong
        "IN", // India
        "ID", // Indonesia
        "IE", // Ireland
        "IL", // Israel
        "IT", // Italy
        "JP", // Japan
        "LU", // Luxembourg
        "MX", // Mexico
        "NL", // Netherlands
        "PA", // Panama
        "PR", // Puerto Rico
        "SG", // Singapore
        "ZA", // South Africa
        "KR", // South Korea
        "ES", // Spain
        "SE", // Sweden
        "CH", // Switzerland
        "TW", // Taiwan
        "US" // United States
    },
    
<b>P/E (Default: Any) -</b>


    pe: {
        "over45", // Over 45
        "over40", // Over 40
        "over35", // Over 35 
        "over30", // Over 30 
        "over25", // Over 25 
        "over20", // Over 20 
        "over15", // Over 15 
        "over10", // Over 10 
        "over5", // Over 5 
        "under45", // Under 45 
        "under40", // Under 40 
        "under35", // Under 35 
        "under30", // Under 30 
        "under25", // Under 25 
        "under20", // Under 20 
        "under15", // Under 15 
        "under10", // Under 10 
        "under5", // Under 5 
        "high", // High (>50)
        "profitable", // Profitable (>0)
        "low" // Low (<15)
    }
    
<b>Stock Rating (Default: Any) -</b>


    stockrating: {
        "strongbuy", // Strong Buy
        "buybetter", // Buy or better
        "buy", // Buy
        "holdbetter", // Hold or better
        "hold", // Hold
        "holdworse", // Hold or worse
        "sell", // Sell
        "sellworse", // Sell or worse
        "strongsell" // Strong Sell
    }
    
<b>SMA (Simple Moving Average) (Default: Any) -</b>

    
    sma: {
        "over200", // Price Over SMA200
        "over50", // Price Over SMA50
        "over21", // Price Over SMA21
        "over10", // Price Over SMA10
        "over5", // Price Over SMA5
        "over3", // Price Over SMA3
        "under200", // Price under SMA200
        "under50", // Price under SMA50
        "under21", // Price under SMA21
        "under10", // Price under SMA10
        "under5", // Price under SMA5
        "under3" // Price under SMA3
    }

<b>Target Symbols - filter by one or more of our supported symbols (full list is available under <code>/api/v1/resources/finance/assets</code>) (Default: Any) -</b>

    
    targetsymbols: {
        // full list is available under <code>/api/v1/resources/finance/assets</code>
    }




<!--To negate the filter use !: ?filter=firstName:!John. Note that you can negate multiple values like this: ?filter=firstName:!John,!Bob. This filter rule will exclude all Johns and Bobs from the response.-->
<!--You can use range filters like this: ?filter=amount:1..10.-->
<!--You can use gte (greater than or equals) filter like this: ?filter=amount:1.., or lte (less than or equals) than filter like this: ?filter=amount:..10. This also works for datetime-based fields.-->
<!--You can create some predefined values lists and use them in filter: ?filter=firstName:@yourListName. You can also exclude list values: ?filter=firstName:!@yourListName.-->
<!--Datetime-based fields accept values formatted using RFC 3339 like this: ?filter=createdTime:2021-02-14T13:30:00Z.-->
`;

