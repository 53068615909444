export const filterSnippet = `
hoopsAI provides asset filtering. You can use the <code>?filters</code> param on any of the <b>'Data'</b> endpoints to define which assets should be included in the response content.

The following section provides a detailed explanation of how to construct filter queries with comparison logic. This will allow developers to apply precise criteria to search queries.


<ul>
  <li>Fields and values in filter are separated with <code>_</code> i.e.:   </li>
  
\`\`\`
?filters=assetclass_currencies
\`\`\`

  <br/>
  <li>Multiple filters are separated with <code>,</code> (url-encode value %2C) i.e.: </li>
  
\`\`\`
?filters=assetclass_currencies,targetassetnumber_7
\`\`\`

They will be joined with AND logic. In this example: assetclass:currencies AND targetassetnumber:7.

  <br/>
  <li>Numerical comparisons such as greater than or equal to (ge), less than or equal to (le), and equal to (eq). The general format to use these filters in your API request is as follows:</li>

\`\`\`
?filters=fieldname_operator#value&fieldname_operator#value
\`\`\`

Here, \`fieldname\` is the name of the field you wish to filter on, \`operator\` is the comparison operator (e.g., ge, le, eq), and \`value\` is the number you want to compare the field value against. Use \`&\` to chain multiple filter rules together, which will be applied with AND logic.

#### Operators:

- \`ge\` - Greater than or equal to
- \`le\` - Less than or equal to
- \`eq\` - Equal to

#### Special Characters:

- \`#\` - Used to separate the operator from the value to compare to. (URL Encoded %23)
- \`&\` - Used to chain multiple filter rules together. (URL Encoded %26)

#### Example Usage:

1. To filter assets where a particular field is greater than or equal to 2000 AND less than or equal to 3000, the filter query would look like this:

   \`\`\`
   ?filters=marketcap_ge#2000&marketcap_le#3000
   \`\`\`

   This applies two filters to the \`marketcap\`: one ensuring the value is \`>= 2000\` and another that it is \`<= 3000\`.
  <br/>
  <li>You can use multiple values using <code>|</code> (url-encode value %7C) as values separator i.e.: <code>?filters=assetclass_currencies|stocks</code>. Multiple values specified for a field will be joined with OR logic. In this example: assetclass:currencies OR assetclass:stocks.</li>
</ul>

<b>Below is a complete list of current supported filters detailed by keys and possible values:</b>


<b>Asset Class <u>(Default: "stocks")</u> -</b>


    assetclass: {
        "currencies",
        "indices",
        "stocks",
        "commodities",
        "cryptocurrency",
        "etfs"
    }

<b>Stock Exchange -</b>


    stockexchange: {
        "XNYS", // New York stock exchange
        "XNAS" // Nasdaq
    }
    
<b>Stock Index -</b>


    stockindex: {
        "^GSPC", // S&P 500
        "^DJI" // Dow Jones
    }
    
<b>Stock Index -</b>


    stocksector: {
        "basic_materials",
        "communication_services",
        "conglomerates",
        "consumer_cyclical",
        "consumer_defensive",
        "energy",
        "financial_services",
        "healthcare",
        "industrials",
        "real_estate",
        "technology",
        "utilities"
    }
    
    
<b>Stock Country -</b>


    stockcountry: {
        "AR", // Argentina
        "AU", // Australia
        "BE", // Belgium
        "BM", // Bermuda
        "BR", // Brazil
        "CA", // Canada
        "KY", // Cayman Islands
        "CL", // Chile
        "CN", // China
        "CO", // Colombia
        "CY", // Cyprus
        "DK", // Denmark
        "FR", // France
        "DE", // Germany
        "GB", // Great Britain (UK)
        "HK", // Hong Kong
        "IN", // India
        "ID", // Indonesia
        "IE", // Ireland
        "IL", // Israel
        "IT", // Italy
        "JP", // Japan
        "LU", // Luxembourg
        "MX", // Mexico
        "NL", // Netherlands
        "PA", // Panama
        "PR", // Puerto Rico
        "SG", // Singapore
        "ZA", // South Africa
        "KR", // South Korea
        "ES", // Spain
        "SE", // Sweden
        "CH", // Switzerland
        "TW", // Taiwan
        "US" // United States
    },
    

<b>Stock Industry -</b>


    'stockindustry' : {
        "advertising_agencies" // Advertising Agencies
        "aerospace_defense" // Aerospace & Defense
        "agricultural_inputs" // Agricultural Inputs
        "agriculture" // Agriculture
        "airlines" // Airlines
        "aluminum" // Aluminum
        "apparel_manufacturing" // Apparel Manufacturing
        "apparel_retail" // Apparel Retail
        "application_software" // Application Software
        "asset_management" // Asset Management
        "auto_manufacturers" // Auto Manufacturers
        "auto_parts" // Auto Parts
        "auto_truck_dealerships" // Auto & Truck Dealerships
        "banks" // Banks
        "banks_diversified" // Banks Diversified
        "banks_regional" // Banks Regional
        "beverages_alcoholic" // Beverages - Alcoholic
        "beverages_brewers" // Beverages Brewers
        "beverages_non_alcoholic" // Beverages Non-Alcoholic
        "beverages_wineries_distilleries" // Beverages Wineries & Distilleries
        "biotechnology" // Biotechnology
        "broadcasting" // Broadcasting
        "building_materials" // Building Materials
        "building_products_equipment" // Building Products & Equipment
        "business_equipment_supplies" // Business Equipment & Supplies
        "business_services" // Business Services
        "capital_markets" // Capital Markets
        "chemicals" // Chemicals
        "communication_equipment" // Communication Equipment
        "communication_services" // Communication Services
        "computer_hardware" // Computer Hardware
        "confectioners" // Confectioners
        "conglomerates" // Conglomerates
        "consulting_services" // Consulting Services
        "consumer_electronics" // Consumer Electronics
        "consumer_packaged_goods" // Consumer Packaged Goods
        "copper" // Copper
        "credit_services" // Credit Services
        "department_stores" // Department Stores
        "diagnostics_research" // Diagnostics & Research
        "discount_stores" // Discount Stores
        "drug_manufacturers" // Drug Manufacturers
        "drug_manufacturers_general" // Drug Manufacturers General
        "drug_manufacturers_general_specialty_generic" // Drug Manufacturers General Specialty & Generic
        "drug_manufacturers_specialty_generic" // Drug Manufacturers Specialty & Generic
        "education_training_services" // Education & Training Services
        "electrical_equipment_parts" // Electrical Equipment & Parts
        "electronic_components" // Electronic Components
        "electronic_gaming_multimedia" // Electronic Gaming & Multimedia
        "electronics_computer_distribution" // Electronics & Computer Distribution
        "engineering_construction" // Engineering & Construction
        "entertainment" // Entertainment
        "farm_heavy_construction_machinery" // Farm & Heavy Construction Machinery
        "farm_products" // Farm Products
        "financial_conglomerates" // Financial Conglomerates
        "financial_data_stock_exchanges" // Financial Data & Stock Exchanges
        "food_distribution" // Food Distribution
        "footwear_accessories" // Footwear & Accessories
        "furnishings_fixtures_appliances" // Furnishings, Fixtures & Appliances
        "gambling" // Gambling
        "gold" // Gold
        "grocery_stores" // Grocery Stores
        "health_care_plans" // Health Care Plans
        "health_information_services" // Health Information Services
        "healthcare_plans" // Healthcare Plans
        "home_improvement_retail" // Home Improvement Retail
        "household_personal_products" // Household & Personal Products
        "industrial_distribution" // Industrial Distribution
        "information_technology_services" // Information Technology Services
        "insurance" // Insurance
        "insurance_brokers" // Insurance Brokers
        "insurance_diversified" // Insurance Diversified
        "insurance_life" // Insurance - Life
        "insurance_property_casualty" // Insurance Property & Casualty
        "insurance_reinsurance" // Insurance Reinsurance
        "insurance_specialty" // Insurance Specialty
        "integrated_freight_logistics" // Integrated Freight & Logistics
        "internet_content_information" // Internet Content & Information
        "internet_retail" // Internet Retail
        "leisure" // Leisure
        "lodging" // Lodging
        "luxury_goods" // Luxury Goods
        "marine_shipping" // Marine Shipping
        "medical_care_facilities" // Medical Care Facilities
        "medical_devices" // Medical Devices
        "medical_distribution" // Medical Distribution
        "medical_instruments_equipment" // Medical Instruments & Equipment
        "medical_instruments_supplies" // Medical Instruments & Supplies
        "metal_fabrication" // Metal Fabrication
        "metals_mining" // Metals & Mining
        "mortgage_finance" // Mortgage Finance
        "oil_gas_e_p" // Oil & Gas - E&P
        "oil_gas_equipment_services" // Oil & Gas Equipment & Services
        "oil_gas_integrated" // Oil & Gas - Integrated
        "oil_gas_midstream" // Oil & Gas Midstream
        "oil_gas_refining_marketing" // Oil & Gas Refining & Marketing
        "other_industrial_metals_mining" // Other Industrial Metals & Mining
        "packaged_foods" // Packaged Foods
        "packaging_containers" // Packaging & Containers
        "paper_paper_products" // Paper & Paper Products
        "personal_services" // Personal Services
        "pharmaceutical_retailers" // Pharmaceutical Retailers
        "publishing" // Publishing
        "railroads" // Railroads
        "real_estate_diversified" // Real Estate Diversified
        "real_estate_services" // Real Estate Services
        "recreational_vehicles" // Recreational Vehicles
        "reit_diversified" // REIT Diversified
        "reit_healthcare_facilities" // REIT Healthcare Facilities
        "reit_hotel_motel" // REIT Hotel & Motel
        "reit_industrial" // REIT Industrial
        "reit_mortgage" // REIT Mortgage
        "reit_office" // REIT Office
        "reit_residential" // REIT Residential
        "reit_retail" // REIT Retail
        "reit_specialty" // REIT Specialty
        "rental_leasing_services" // Rental & Leasing Services
        "residential_construction" // Residential Construction
        "resorts_casinos" // Resorts & Casinos
        "restaurants" // Restaurants
        "retail_apparel_specialty" // Retail Apparel & Specialty
        "scientific_technical_instruments" // Scientific & Technical Instruments
        "security_protection_services" // Security & Protection Services
        "semiconductor_equipment_materials" // Semiconductor Equipment & Materials
        "semiconductors" // Semiconductors
        "software_application" // Software Application
        "software_infrastructure" // Software Infrastructure
        "solar" // Solar
        "specialty_business_services" // Specialty Business Services
        "specialty_chemicals" // Specialty Chemicals
        "specialty_industrial_machinery" // Specialty Industrial Machinery
        "specialty_retail" // Specialty Retail
        "staffing_employment_services" // Staffing & Employment Services
        "steel" // Steel
        "telecom_services" // Telecom Services
        "tobacco" // Tobacco
        "tools_accessories" // Tools & Accessories
        "travel_services" // Travel Services
        "trucking" // Trucking
        "utilities_diversified" // Utilities Diversified
        "utilities_independent_power_producers" // Utilities Independent Power Producers
        "utilities_regulated_electric" // Utilities Regulated Electric
        "utilities_regulated_gas" // Utilities Regulated Gas
        "utilities_regulated_water" // Utilities Regulated Water
        "utilities_renewable" // Utilities Renewable
        "waste_management" // Waste Management
    }

<b>Recent Earnings Date (in days) -</b>
    
    'recentearningsdate'

    
<li>Suggested values:</li>
 
    'eq#0' // Today
    'eq#-1' // Yesterday
    'ge#-1&le#0' // Since Yesterday
    'ge#-2&le#-1' // Previous 2 days
    'ge#-5&le#-1' // Previous 5 days
    'ge#-7&le#-1' // Previous 7 days

<b>Upcoming Earnings Date (in days) -</b>

    'upcomingearningsdate'
    
<li>Suggested values:</li>
 
    'eq#0' // Today
    'eq#1' // Tomorrow
    'ge#0&le#1' // Until Tomorrow
    'ge#0&le#2' // Next 2 days
    'ge#0&le#5' // Next 5 days
    'ge#0&le#7' // Next 7 days

<b>Market Cap (in Millions)-</b>
    
    'marketcap'
    
<li>Suggested values:</li>
 
    'ge#200000' // Mega (over $200B)
    'le#200000&ge#10000' // Large ($10B to $200B)
    'le#10000&ge#2000' // Mid ($2B to $10B)
    'le#2000&ge#300' // Small ($300M to $2B)
    'le#300&ge#50' // Micro ($50M to $300M)
    'ge#10000' // Large and over (over $10B)
    'ge#2000' // Mid and over (over $2B)
    'ge#300' // Small and over (over $300M)
    'ge#50' // Micro and over (over $50M)
    'le#200000' // Large and under (under $200B)
    'le#10000' // Mid and under (under $10B)
    'le#2000' // Small and under (under $2B)
    'le#300' // Micro and under (under $300M)

<b>Last Session Volume Suggested range: 0 - 10,000 (In thousands) - </b>
    
    'lastsessionvolume'
    
<li>Suggested values:</li>
 
    'le#50' // Under 50k
    'le#100' // Under 100k
    'le#500' // Under 500k
    'le#1000' // Under 1m
    'ge#50' // Over 50k
    'ge#100' // Over 100k
    'ge#200' // Over 200k
    'ge#500' // Over 500k
    'ge#1000' // Over 1m
    'ge#2000' // Over 2m
    'ge#100&le#500' // 100k to 500k
    'ge#500&le#1000' // 500k to 1m

<b>Latest session Volume relative to average. Suggested range: 0 - 50 -</b>
    
    'lastsessionrelativevolume'
    
<li>Suggested values:</li>
 
    'ge#0.25' // Over 0.25
    'ge#0.5' // Over 0.5
    'ge#1' // Over 1
    'ge#1.5' // Over 1.5
    'ge#2' // Over 2
    'ge#3' // Over 3
    'ge#5' // Over 5
    'ge#10' // Over 10
    'le#0.25' // Under 0.25
    'le#0.5' // Under 0.5
    'le#1' // Under 1
    'le#2' // Under 2
    'le#3' // Under 3

<b>Price % Change from open. Suggested range: -100 - 10,000 -</b>
    
    'lastchangepercentage'
    
<li>Suggested values:</li>
 
    'ge#0' // Up
    'ge#1' // Up 1%
    'ge#2' // Up 2%
    'ge#3' // Up 3%
    'ge#5' // Up 5%
    'ge#10' // Up 10%
    'ge#15' // Up 15%
    'ge#20' // Up 20%
    'le#0' // Down
    'le#-1' // Down 1%
    'le#-2' // Down 2%
    'le#-3' // Down 3%
    'le#-5' // Down 5%
    'le#-10' // Down 10%
    'le#-15' // Down 15%
    'le#-20' // Down 20%
    
<b>Beta -</b>
    
    'beta'
    
<li>Suggested values:</li>
 
    'le#0' // Under 0
    'le#0.5' // Under 0.5
    'le#1' // Under 1
    'le#1.5' // Under 1.5
    'le#2' // Under 2
    'ge#0' // Over 0
    'ge#0.5' // Over 0.5
    'ge#1' // Over 1
    'ge#1.5' // Over 1.5
    'ge#2' // Over 2
    'ge#2.5' // Over 2.5
    'ge#3' // Over 3
    'ge#4' // Over 4
    'ge#0&le#0.5' // 0 to 0.5
    'ge#0&le#1' // 0 to 1
    'ge#0.5&le#1' // 0.5 to 1
    'ge#0.5&le#1.5' // 0.5 to 1.5
    'ge#1&le#1.5' // 1 to 1.5
    'ge#1&le#2' // 1 to 2

<b>Price Earnings Ratio -</b>
    
    'priceearningsratio'
   
<li>Suggested values:</li>
 
    'le#15': // Low (<15)
    'ge#0': // Profitable (>0)
    'ge#50': // High (>50)
    'le#5': // Under 5
    'le#10': // Under 10
    'le#15': // Under 15
    'le#20': // Under 20
    'le#25': // Under 25
    'le#30': // Under 30
    'le#35': // Under 35
    'le#40': // Under 40
    'le#45': // Under 45
    'le#50': // Under 50
    'ge#5': // Over 5
    'ge#10': // Over 10
    'ge#15': // Over 15
    'ge#20': // Over 20
    'ge#25': // Over 25
    'ge#30': // Over 30
    'ge#35': // Over 35
    'ge#40': // Over 40
    'ge#45': // Over 45
    'ge#50': // Over 5

<b>Target Symbols - filter by one or more of our supported symbols (full list is available under <code>/api/v2/resources/finance/assets</code>) -</b>
    
    targetsymbols: {
        // full list is available under <code>/api/v2/resources/finance/assets</code>
    }

`;



export const scopesSnippet = `
### Filter Scope Options for Asset Data

Our API provides a versatile way to tailor your asset data retrieval by using the \`filter_scope\` query parameter. This parameter allows you to specify the scope of data you wish to receive about assets, making your queries more efficient by focusing on the information that matters most to your analysis. Below are the available options for the \`filter_scope\` parameter and what each option includes in the API response:

- **\`overview_scope\`**: Returns a general overview of the asset, including key financial metrics and identifiers such as \`price\`, \`changesPercentage\`, \`change\`, \`previousClose\`, \`pe\` (price to earnings ratio), \`eps\` (earnings per share), \`volume\`, \`marketCap\` (market capitalization), \`sector\`, \`industry\`, and \`country\`. This scope is ideal for getting a quick snapshot of an asset's current standing in the market.

- **\`performance_scope\`**: Focuses on the performance metrics of the asset over various time frames, including \`price\`, \`changesPercentage\`, \`days_5_change\`, \`month_1_change\`, \`month_3_change\`, \`month_6_change\`, \`month_12_change\`, \`ytd_change\` (year-to-date change), \`years_3_change\`, \`years_5_change\`, and \`years_10_change\`. This scope is particularly useful for trend analysis and understanding an asset's historical performance.

- **\`valuation_scope\`**: Provides valuation metrics of the asset, such as \`price_to_sales_ratio\`, \`price_to_book_ratio\`, \`price_to_free_cash_flows_ratio\`, \`price_earnings_ratio\`, \`price_cash_flow_ratio\`, \`enterprise_value_multiple\`, and \`dividend_yield\`. This set of data helps in assessing the asset's valuation and its attractiveness as an investment.

- **\`income_statement_scope\`**: Includes key figures from the asset's income statement like \`accepted_date\`, \`revenue\`, \`gross_profit\`, \`operating_income\`, \`net_income\`, \`ebitda\`, and \`eps_diluted\`. This scope offers a detailed look into the financial performance and profitability of the asset.

- **\`balance_sheet_scope\`**: Covers essential balance sheet items such as \`accepted_date\`, \`total_assets\`, \`total_current_assets\`, \`cash_and_cash_equivalents\`, \`total_liabilities\`, \`total_debt\`, \`net_debt\`, and \`total_investments\`. This scope is essential for evaluating the financial health and stability of the asset.

- **\`cash_flow_scope\`**: Delivers critical cash flow statement metrics including \`accepted_date\`, \`capital_expenditure\`, \`operating_cash_flow\`, \`dividends_paid\`, \`free_cash_flow\`, \`stock_based_compensation\`, \`common_stock_issued\`, and \`common_stock_repurchased\`. This set of data is crucial for understanding the cash generation and spending patterns of the asset.

- **\`technical_scope\`**: Focuses on technical analysis indicators such as \`trend_macd\`, \`trend_macd_signal\`, \`trend_cci\`, \`momentum_rsi\`, \`momentum_stoch_rsi_k\`, \`momentum_stoch_rsi_d\`, and \`momentum_ao\`. This scope is particularly useful for traders and analysts looking for technical entry and exit signals.

- **\`earnings_scope\`**: Focuses on earnings metrics such as \`eps_forecast\`, \`revenue_forecast\`, \`earnings_time\`, \`earnings_quarter\`, \`eps_actual\`, \`revenue_actual\`, \`earnings_year\`, \`earnings_date\`. This scope is particularly useful for gaining a quick overview of the assets earnings.

By utilizing these \`filter_scope\` options, you can streamline your queries to receive only the data you need, enhancing the efficiency and effectiveness of your financial analysis and decision-making processes.
`;


export const sortKeysSnippet = `
### Sorting Options for Assets

When retrieving asset information through our API, you can tailor your queries to sort the results based on specific metrics of interest. The \`sort_key\` query parameter allows you to define how the returned assets are ordered. The \`sort_key\` parameter can be any of the filtering fields, as long as that field is returned in the current results. The sorting order can be specified as ascending or descending using the \`sort_asc\` parameter (Default - false).

By leveraging these sorting options, developers can create more targeted and relevant asset queries, enhancing the utility and efficiency of financial applications and analyses.
`;

