import {
    filtersQueryParams,
    financialContentQueryParams,
} from './specSnippetsV2';
import {userSpecPaths} from "./userSpecPathsV2";
import {articleSnippet} from "./articleSnippetsV2";


export const specPaths = {
    "/api/v2/resources/finance/daily/{asset+}": {
        "get": {
            "tags": [
                "Asset Content & Analysis"
            ],
            "summary": "daily",
            "operationId": "daily",
            "description": "Get near real-time content within the context of the last trading day for the selected asset symbol",
            "parameters": financialContentQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "oneOf": [
                                    {"$ref": "#/components/schemas/FinanceContent"},
                                    {"$ref": "#/components/schemas/MultipleFinanceContent"},
                                ]
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "asset = TARGET_ASSET\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v2/resources/finance/daily/{}\".format(asset), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v2/resources/finance/article/top_gainers": articleSnippet(
        'Top Gainers',
        'top_gainers',
        "Generate an article covering the top gaining assets of the latest trading session.",
        '/prod/api/v2/resources/finance/article/top_gainers'
    ),
    "/api/v2/resources/finance/article/top_losers": articleSnippet(
        'Top Losers',
        'top_losers',
        "Generate an article covering the top losing assets of the latest trading session.",
        '/prod/api/v2/resources/finance/article/top_losers'
    ),
    "/api/v2/resources/finance/article/market_overview": articleSnippet(
        'Market Overview',
        'market_overview',
        "Generate an article covering the top news of the major indices and markets for the latest trading session.",
        '/prod/api/v2/resources/finance/article/market_overview'
    ),
    "/api/v2/resources/finance/article/upcoming_earnings": articleSnippet(
        'Upcoming Earnings',
        'upcoming_earnings',
        "Generate an article covering the assets that are scheduled to publish quarterly earning reports in the upcoming 30 days",
        '/prod/api/v2/resources/finance/article/recent_earnings'
    ),
    "/api/v2/resources/finance/article/recent_earnings": articleSnippet(
        'Recent Earnings',
        'recent_earnings',
        "Generate an article covering the assets that recently published quarterly earning reports with real time market updates..",
        '/prod/api/v2/resources/finance/article/recent_earnings'
    ),
    "/api/v2/resources/finance/article/unusual_high_volume": articleSnippet(
        'Unusual High Volume',
        'unusual_high_volume',
        "Generate an article covering assets showing unusually high volume in the current (or last) trading session.",
        '/prod/api/v2/resources/finance/article/unusual_high_volume'
    ),
    "/api/v2/resources/finance/assets": {
        "get": {
            "tags": [
                "Data"
            ],
            "summary": "assets",
            "operationId": "assets",
            "description": "Get list of all available financial assets and their properties",
            "parameters": filtersQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Assets"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v2/resources/finance/customer_assets\"\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v2/resources/finance/customer_assets": {
        "get": {
            "tags": [
                "Data"
            ],
            "summary": "Customer Assets",
            "operationId": "customer_assets",
            "description": "Get list of all financial assets and their properties under the configuration of the selected customer.",
            "parameters": filtersQueryParams,
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Assets"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Server Error"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v2/resources/finance/assets\"\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    ...userSpecPaths,
}
