import {bestPracticesDescription, standardsDescription, authDescription, requestBodies} from './specSnippets';
import {filterSnippet} from './filterSnippet';
import {specPaths} from "./specPaths";
import {responseSchemas} from "./responseSchemas";


export const spec = {
    "openapi": "3.0.1",
    "info": {
        "title": "hoopsAI Content REST API - V1",
        "description": "",
        "contact": {
            "name": "Amir Erell",
            "email": "amir@hoopsai.com"
        },
        "version": "2022-10-26"
    },
    "tags": [
        {
            "name": "Introduction",
            "description": `hoopsAI allows you to generate on-demand, personalized content automatically for financial news and events. This API is documented in **OpenAPI format**. `
        },
        {
            "name": "Authentication",
            "description": authDescription
        },
        {
            "name": "Standards and Conventions",
            "description": standardsDescription
        },

        {
            "name": "Using Filters",
            "description": filterSnippet
        },

        {
            "name": "Best Practices",
            "description": bestPracticesDescription
        },
        {
            "name": "Asset Content & Analysis",
            "description": "Generate Financial Content & analysis on Demand on thousands of assets"
        },
        {
            "name": "Personalized Content & Analysis",
            "description": "Generate Financial Content unique for individual users based on a list of assets or using our Watchlists APIs"
        },
        {
            "name": "Market Research",
            "description": "Generate endless Market Research over multiple topics, powered by a wide range of asset screeners and parameters"
        },
        {
            "name": "Data",
            "description": "Get data related to hoopsAI's offerings"
        },
        {
            "name": "Users",
            "description": "Create and manage a user table and user watchlists. \n\n" +
            "Users are identifiable only through a UUID, personal information cannot be saved per user. \n\n" +
            "Watchlists are a list of symbols attached to a specific user, each watchlist has both a UUID and a name. \n\n" +
            "Watchlists symbols can only be selected from the supported list of assets, which can be found via https://api.hoopsai.com/prod/api/v1/resources/finance/assets. \n\n" +
            "Each user has at least one watchlist, called 'default', which cannot be deleted. Additional watchlists per user can be created. \n\n" +
            "Authorization is done via api key and access to other customers' users is forbidden. \n\n"
        },
        {
            "name": "preview",
            "description": "Generate Game Previews"
        },
        {
            "name": "recap",
            "description": "Generate Game Recaps"
        },
        {
            "name": "matchups",
            "description": "Get data for a requested matchup"
        },
        {
            "name": "lineups",
            "description": "Get lineups for a requested matchup"
        },
        {
            "name": "schedule",
            "description": "Get schedule for a requested date"
        },
        {
            "name": "daily-content",
            "description": "Get daily content for a requested date"
        },
        {
            "name": "journal",
            "description": "Get or Post generated content into a customer-based journal"
        }
    ],
    "x-tagGroups": [
        {
            "name": "Overview",
            "tags": [
                "Introduction",
                "Authentication",
                "Standards and Conventions",
                "Using Filters",
                "Best Practices",
            ]
        },
        {
            "name": "Finance",
            "tags": [
                "Asset Content & Analysis",
                "Personalized Content & Analysis",
                "Market Research",
                "Data",
                "Users",
            ]
        },
        // {
        //     "name": "Sports",
        //     "tags": [
        //         "preview",
        //         "recap",
        //         "journal",
        //         "daily-content",
        //         "matchups",
        //         "lineups",
        //         "schedule"
        //     ]
        // }
    ],
    "servers": [
        {
            "url": "https://api.hoopsai.com/prod",
            "variables": {
                "basePath": {
                    "default": "/prod"
                }
            }
        }
    ],
    "paths": specPaths,
    "components": {
        "schemas": {
            ...responseSchemas
        },
        "requestBodies": requestBodies,
        "securitySchemes": {
            "api_key": {
                "type": "apiKey",
                "name": "x-api-key",
                "in": "header"
            }
        }
    }
};
