export const sportSpecPaths = {
    "/api/v1/resources/nba/lineups": {
        "get": {
            "tags": [
                "lineups"
            ],
            "description": "Return the lineups of teams for a single game",
            "responses": {
                "200": {
                    "description": "200 response",
                    "headers": {
                        "Access-Control-Allow-Origin": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Lineups"
                            }
                        }
                    }
                }
            }
        }
    },
    "/api/v1/resources/nba/games/preview/{gameId+}": {
        "get": {
            "tags": [
                "preview"
            ],
            "description": "Return Preview for a single NBA game ",
            "summary": "Return Preview for a single NBA game ",
            "parameters": [
                {
                    "name": "length",
                    "in": "query",
                    "description": "Choose target length for the generated article",
                    "schema": {
                        "type": "integer",
                        "minimum": 1,
                        "default": 5,
                        "maximum": 10
                    }
                },
                {
                    "name": "players_bias",
                    "in": "query",
                    "description": "List of playerIds to focus on throughout the article. playerIds are identical to stats.nba.com",
                    "schema": {
                        "type": "array",
                        "items": {
                            "type": "integer"
                        }
                    }
                },
                {
                    "name": "gameId",
                    "in": "path",
                    "required": true,
                    "description": "Target gameId, identical to the official stats.nba.com gameIds. Sample: 41800401",
                    "schema": {
                        "type": "int"
                    }
                },
                {
                    "name": "betting",
                    "in": "query",
                    "description": "Indicates whether betting related content will be generated",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "promotions",
                    "in": "query",
                    "description": "List of promotions that are to be included in the generated content",
                    "schema": {
                        "type": "array",
                        "items": {
                            "type": "string",
                            "enum": [
                                "nbastore",
                                "nike",
                                "amazon",
                                "leaguepass"
                            ]
                        }
                    }
                },
                {
                    "name": "team_bias",
                    "in": "query",
                    "description": "Sets a team by teamId that the article will focus on. Must add a team_bias_level parameter as well",
                    "schema": {
                        "type": "integer"
                    }
                },
                {
                    "name": "team_bias_level",
                    "in": "query",
                    "description": "Sets the level from 1 to 5 of how much bias to add to the chosen team in team_bias",
                    "schema": {
                        "type": "integer",
                        "minimum": 1,
                        "maximum": 5
                    }
                },
                {
                    "name": "language",
                    "in": "query",
                    "description": "Choose target language, values must correspond to locale format: xx_xx",
                    "schema": {
                        "type": "string",
                        "enum": [
                            "en_us",
                            "es_es",
                            "he_il"
                        ]
                    }
                },
                // {
                //     "name": "enable_api",
                //     "in": "query",
                //     "description": "Internal parameter, indicating whether API is allowed to call subsequent calls to self",
                //     "schema": {
                //         "type": "boolean",
                //         "default": false
                //     }
                // },
                {
                    "name": "template",
                    "in": "query",
                    "description": "Internal parameter, allowing to set a specific DocumentStructure to adhere to",
                    "schema": {
                        "type": "string"
                    }
                },
                {
                    "name": "videos_enabled",
                    "in": "query",
                    "description": "EXPERIMENTAL Indicates whether related videos will be added to content",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "images_enabled",
                    "in": "query",
                    "description": "When enabled, a new key-value pair will be added to the response: image_url: SOME_URL_STRING The chosen image will be the one most relevant to the generated content",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "metadata_level",
                    "in": "query",
                    "description": "Indicates the level of metadata to include in the response",
                    "schema": {
                        "type": "integer",
                        "minimum": 0,
                        "maximum": 2
                    }
                },
                {
                    "name": "gec_enabled",
                    "in": "query",
                    "description": "Indicates whether to enable a Neural-network based Grammar Error Correction. This additional stage increases the response time significantly.",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "separate_header",
                    "in": "query",
                    "description": "Indicates whether the header will be separated from the body in the response",
                    "schema": {
                        "type": "string"
                    }
                }
            ],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Content"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid Game ID supplied"
                },
                "404": {
                    "description": "Game not found"
                },
                "405": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "game_id = TARGET_GAME_ID\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/nba/games/preview/{}\".format(game_id), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/nba/matchups": {
        "get": {
            "tags": [
                "matchups"
            ],
            "description": "Return a data for a single matchup of an NBA game ",
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Empty"
                            }
                        }
                    }
                }
            }
        }
    },
    "/api/v1/resources/nba/schedule/{proxy+}": {
        "get": {
            "tags": [
                "schedule"
            ],
            "description": "Return the schedule for a specific date",
            "parameters": [
                {
                    "name": "proxy",
                    "in": "path",
                    "required": true,
                    "schema": {
                        "type": "string"
                    }
                }
            ],
            "responses": {
                "200": {
                    "description": "200 response",
                    "headers": {
                        "Access-Control-Allow-Origin": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Empty"
                            }
                        }
                    }
                }
            }
        }
    },
    "/api/v1/resources/nba/games/recap/{gameId+}": {
        "get": {
            "tags": [
                "recap"
            ],
            "description": "Return Recap for a single NBA game ",
            "parameters": [
                {
                    "name": "length",
                    "in": "query",
                    "description": "Choose target length for the generated article",
                    "schema": {
                        "type": "integer",
                        "minimum": 1,
                        "default": 5,
                        "maximum": 10
                    }
                },
                {
                    "name": "players_bias",
                    "in": "query",
                    "description": "List of playerIds to focus on throughout the article. playerIds are identical to stats.nba.com",
                    "schema": {
                        "type": "array",
                        "items": {
                            "type": "integer"
                        }
                    }
                },
                {
                    "name": "gameId",
                    "in": "path",
                    "required": true,
                    "description": "Target gameId, identical to the official stats.nba.com gameIds. Sample: 41800401",
                    "schema": {
                        "type": "int"
                    }
                },
                {
                    "name": "betting",
                    "in": "query",
                    "description": "Indicates whether betting related content will be generated",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "promotions",
                    "in": "query",
                    "description": "List of promotions that are to be included in the generated content",
                    "schema": {
                        "type": "array",
                        "items": {
                            "type": "string",
                            "enum": [
                                "nbastore",
                                "nike",
                                "amazon",
                                "leaguepass"
                            ]
                        }
                    }
                },
                {
                    "name": "team_bias",
                    "in": "query",
                    "description": "Sets a team by teamId that the article will focus on. Must add a team_bias_level parameter as well",
                    "schema": {
                        "type": "integer"
                    }
                },
                {
                    "name": "team_bias_level",
                    "in": "query",
                    "description": "Sets the level from 1 to 5 of how much bias to add to the chosen team in team_bias",
                    "schema": {
                        "type": "integer",
                        "minimum": 1,
                        "maximum": 5
                    }
                },
                {
                    "name": "language",
                    "in": "query",
                    "description": "Choose target language, values must correspond to locale format: xx_xx",
                    "schema": {
                        "type": "string",
                        "enum": [
                            "en_us",
                            "es_es",
                            "he_il"
                        ]
                    }
                },
                // {
                //     "name": "enable_api",
                //     "in": "query",
                //     "description": "Internal parameter, indicating whether API is allowed to call subsequent calls to self",
                //     "schema": {
                //         "type": "boolean",
                //         "default": false
                //     }
                // },
                {
                    "name": "template",
                    "in": "query",
                    "description": "Internal parameter, allowing to set a specific DocumentStructure to adhere to",
                    "schema": {
                        "type": "string"
                    }
                },
                {
                    "name": "twitter_enabled",
                    "in": "query",
                    "description": "Indicates whether related Tweets will be embedded in the content",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "videos_enabled",
                    "in": "query",
                    "description": "EXPERIMENTAL Indicates whether related videos will be added to content",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "images_enabled",
                    "in": "query",
                    "description": "When enabled, a new key-value pair will be added to the response: image_url: SOME_URL_STRING The chosen image will be the one most relevant to the generated content",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "metadata_level",
                    "in": "query",
                    "description": "Indicates the level of metadata to include in the response",
                    "schema": {
                        "type": "integer",
                        "minimum": 0,
                        "maximum": 2
                    }
                },
                {
                    "name": "gec_enabled",
                    "in": "query",
                    "description": "Indicates whether to enable a Neural-network based Grammar Error Correction. This additional stage increases the response time significantly.",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "separate_header",
                    "in": "query",
                    "description": "Indicates whether the header will be separated from the body in the response",
                    "schema": {
                        "type": "boolean",
                        "default": false
                    }
                },
                {
                    "name": "doc_group",
                    "in": "query",
                    "description": "Indicates a specific Doc Structure Group to base content on",
                    "schema": {
                        "type": "string"
                    }
                },
                {
                    "name": "twitter_mode",
                    "in": "query",
                    "description": "When twitter_enabled is set to true, this sets the HTML output for the embedded tweets. twitter_widget will follow the twitter guide: https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference wordpress_plugin is used with the twitter While wordpress plugin: https://blog.twitter.com/developer/en_us/a/2015/twitter-plugin-wordpress.html",
                    "schema": {
                        "type": "string",
                        "enum": [
                            'wordpress_plugin',
                            'twitter_widget'
                        ]
                    }
                }
            ],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/Content"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid Game ID supplied"
                },
                "404": {
                    "description": "Game not found"
                },
                "405": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "game_id = TARGET_GAME_ID\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/nba/games/recap/{}\".format(game_id), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/nba/journal/recap/{gameId+}": {
        "get": {
            "tags": [
                "journal"
            ],
            "description": "Return Saved Recap from Journal for a single NBA game",
            "parameters": [],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/JournalContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid Game ID supplied"
                },
                "404": {
                    "description": "Game not found"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "game_id = TARGET_GAME_ID\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/nba/journal/recap/{}\".format(game_id), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
        "post": {
            "tags": [
                "journal"
            ],
            "description": "Post Recap to Journal for a single NBA game",
            "parameters": [],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/JournalPost"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid Game ID supplied"
                },
                "404": {
                    "description": "Game not found"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "requestBody": {
                "$ref": '#/components/requestBodies/journal'
            },
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "game_id = TARGET_GAME_ID\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"POST\", \"/prod/api/v1/resources/nba/journal/recap/{}\".format(game_id), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/nba/journal/preview/{gameId+}": {
        "get": {
            "tags": [
                "journal"
            ],
            "description": "Return Saved preview from Journal for a single NBA game",
            "parameters": [],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/JournalContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid Game ID supplied"
                },
                "404": {
                    "description": "Game not found"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "game_id = TARGET_GAME_ID\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/nba/journal/recap/{}\".format(game_id), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        },
        "post": {
            "tags": [
                "journal"
            ],
            "description": "Post preview to Journal for a single NBA game",
            "parameters": [],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/JournalPost"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid Game ID supplied"
                },
                "404": {
                    "description": "Game not found"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "requestBody": {
                "$ref": '#/components/requestBodies/journal'
            },
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "game_id = TARGET_GAME_ID\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"POST\", \"/prod/api/v1/resources/nba/journal/recap/{}\".format(game_id), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/nba/daily/preview/{date+}": {
        "get": {
            "tags": [
                "daily-content"
            ],
            "description": "Get Previews for all games on a given date",
            "parameters": [],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/DailyContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid date supplied"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "year = TARGET_YEAR\n" +
                        "month = TARGET_MONTH\n" +
                        "day = TARGET_DAY\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/nba/daily/preview/{}/{}/{}\".format(year, month, day), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/nba/daily/recap/{date+}": {
        "get": {
            "tags": [
                "daily-content"
            ],
            "description": "Get Recaps for all games on a given date",
            "parameters": [],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/DailyContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid date supplied"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "year = TARGET_YEAR\n" +
                        "month = TARGET_MONTH\n" +
                        "day = TARGET_DAY\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/nba/daily/recap/{}/{}/{}\".format(year, month, day), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
    "/api/v1/resources/nba/daily/all/{date+}": {
        "get": {
            "tags": [
                "daily-content"
            ],
            "description": "Get All Content for all games on a given date",
            "parameters": [],
            "responses": {
                "200": {
                    "description": "200 response",
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/DailyContent"
                            }
                        }
                    }
                },
                "400": {
                    "description": "Invalid date supplied"
                },
                "403": {
                    "description": "Validation exception"
                }
            },
            "security": [
                {
                    "api_key": []
                }
            ],
            "x-code-samples": [
                {
                    "lang": "Python",
                    "source": "import http.client\n" +
                        "import json\n" +
                        "\n" +
                        "year = TARGET_YEAR\n" +
                        "month = TARGET_MONTH\n" +
                        "day = TARGET_DAY\n" +
                        "api_key = YOUR_API_KEY\n" +
                        "headers = {'x-api-key': api_key}\n" +
                        "\n" +
                        "conn = http.client.HTTPSConnection(\"api.hoopsai.com\")\n" +
                        "\n" +
                        "conn.request(\"GET\", \"/prod/api/v1/resources/nba/daily/all/{}/{}/{}\".format(year, month, day), None, headers)\n" +
                        "\n" +
                        "res = conn.getresponse()\n" +
                        "data = json.loads(res.read())\n"
                }
            ]
        }
    },
}
