import React from 'react';
import {Route, Switch} from "react-router-dom";

import Documentation from './v1/Documentation';
import DocumentationV2 from './DocumentationV2';
import NotFound from './NotFound';


export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={DocumentationV2}/>
            <Route path="/documentation" exact component={Documentation}/>
            <Route path="/documentation-v2" exact component={DocumentationV2}/>

            {/* Finally, catch all unmatched routes */}
            <Route component={NotFound}/>
        </Switch>
    );
}