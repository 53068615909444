import React from 'react';
import {Link} from "react-router-dom";

import { makeStyles } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        '&:hover, &:focus': {
            backgroundColor: 'transparent'
        },
    },
    logo: {
        height: '32px',
        margin: '16px'
    },
    btnsWrapper: {
        display: 'flex',
    },
    loginBtn: {
        fontSize: '12px',
        lineHeight: '24px',
        margin: '16px',
        fontWeight: '700',
        '&:hover, &:focus': {
            backgroundColor: 'transparent'
        },
    },
    linkBtn: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '700',
        '&:hover, &:focus': {
            backgroundColor: 'transparent'
        },
    },
    toolbarDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    link: {
        display: 'flex',
        color: 'inherit',
        textDecoration: 'none'
    }
}));

export default function ButtonAppBar() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" color="transparent" className={classes.appBarClasas}>
                <Toolbar className={classes.toolbarDiv}>
                    <div className={classes.btnsWrapper}>
                        <Link className={classes.link} to='/documentation-v2'>
                            <IconButton disableRipple edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <img src={"https://hoops-public-assets.s3.amazonaws.com/Company_Graphics/hoopsAI_color_logo_new.png"} alt={'hoopsLogo'} className={classes.logo}/>
                            </IconButton>
                        </Link>

                        <Divider orientation="vertical" variant="middle" flexItem style={{margin: '10px'}}/>
                    </div>

                    <div className={classes.btnsWrapper}>
                        <Link className={classes.link} to='/documentation'>
                            <Button disableRipple color="inherit" className={classes.linkBtn}>V1 Docs</Button>
                        </Link>
                        <Link className={classes.link} to='/documentation-v2'>
                            <Button disableRipple color="inherit" className={classes.linkBtn}>V2 Docs</Button>
                        </Link>
                        <Link className={classes.link} to={{pathname: 'https://dashboard.hoopsai.com'}} target={'_blank'}>
                            <Button variant="outlined" color="inherit" className={classes.loginBtn}>LOG IN</Button>
                        </Link>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}
