import {bestPracticesDescription, standardsDescription, authDescription, requestBodies} from './specSnippetsV2';
import {filterSnippet, scopesSnippet, sortKeysSnippet} from './filterSnippetV2';
import {specPaths} from "./specPathsV2";
import {responseSchemas} from "./responseSchemasV2";


export const spec = {
    "openapi": "3.0.1",
    "info": {
        "title": "hoopsAI Content REST API - V2",
        "description": "",
        "contact": {
            "name": "Amir Erell",
            "email": "amir@hoopsai.com"
        },
        "version": "2024-02-22"
    },
    "tags": [
        {
            "name": "Introduction",
            "description": `hoopsAI allows you to generate on-demand, personalized content automatically for financial news and events. This API is documented in **OpenAPI format**. `
        },
        {
            "name": "Authentication",
            "description": authDescription
        },
        {
            "name": "Standards and Conventions",
            "description": standardsDescription
        },

        {
            "name": "Filters",
            "description": filterSnippet
        },

        {
            "name": "Scopes",
            "description": scopesSnippet
        },

        {
            "name": "Sort Keys",
            "description": sortKeysSnippet
        },

        {
            "name": "Best Practices",
            "description": bestPracticesDescription
        },
        {
            "name": "Asset Content & Analysis",
            "description": "Generate Financial Content & analysis on Demand on thousands of assets"
        },
        {
            "name": "Market Research",
            "description": "Generate endless Market Research over multiple topics, powered by a wide range of asset screeners and parameters"
        },
        {
            "name": "Data",
            "description": "Get data related to hoopsAI's offerings"
        },
    ],
    "x-tagGroups": [
        {
            "name": "Overview",
            "tags": [
                "Introduction",
                "Authentication",
                "Standards and Conventions",
                "Best Practices",
            ]
        },
        {
            "name": "Finance",
            "tags": [
                "Asset Content & Analysis",
                "Market Research",
                "Data",
            ]
        },
        {
            "name": "Using Filters",
            "tags": [
                "Filters",
                "Sort Keys",
                "Scopes",
            ]
        },
    ],
    "servers": [
        {
            "url": "https://api.hoopsai.com/prod",
            "variables": {
                "basePath": {
                    "default": "/prod"
                }
            }
        }
    ],
    "paths": specPaths,
    "components": {
        "schemas": {
            ...responseSchemas
        },
        "requestBodies": requestBodies,
        "securitySchemes": {
            "api_key": {
                "type": "apiKey",
                "name": "x-api-key",
                "in": "header"
            }
        }
    }
};
